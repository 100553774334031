import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { TextField, Button, Grid, Paper, Typography, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Switch, FormControlLabel, Box, Tooltip, Checkbox, Select, MenuItem } from '@mui/material';
import { IconButton, Popover, List, ListItem, ListItemText } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend, BarElement } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import SettingsIcon from '@mui/icons-material/Settings';
import debounce from 'lodash/debounce';
import { InterestRateChange, LoanData, PrePayment } from './loanUtil';
import './LoanCalculator.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, ChartTooltip, Legend);

const MIN_SIDEBAR_WIDTH = 300;
const MAX_SIDEBAR_WIDTH = 600;
const LOWER_PART_HEIGHT = 200; // Height of the lower part in pixels

// const CALC_MODE = 'FE';

interface LoanCalculatorProps {
  onLoanDataChange: (loanData: LoanData) => void;
}

interface AmortizationEntry {
  month: number;
  emi: number;
  interest: number;
  principal: number;
  remainingBalance: number;
  interestRate: number;
  groupKey?: number | string | null;
  monthStart?: number;
  monthEnd?: number;
  emiStart?: number;
  emiEnd?: number;
  interestStart?: number;
  interestEnd?: number;
  principalStart?: number;
  principalEnd?: number;
  prePayment?: number;
}



interface LoanCalculationResult {
  firstMonthInterest: number | null;
  calculatedEMI: number | null;
  amortizationTable: AmortizationEntry[] | null;
  calculatedTenure: number | null;
  totalInterest: number | null;
}

const MAX_MONTHS = 50 * 12; // 50 years

// Helper function to round numbers to 2 decimal places
function round(num: number): number {
  return Math.round(num * 100) / 100;
}

// 1. Calculate interest for the first month
export function calculateFirstMonthInterest(
  loanAmount: number,
  annualInterestRate: number,
): number {
  const monthlyInterestRate = annualInterestRate / 12 / 100;
  return round(loanAmount * monthlyInterestRate);
}

// 2. Calculate EMI when initial tenure is given
export function calculateEMI(
  loanAmount: number,
  annualInterestRate: number,
  tenureInMonths: number,
): number {
  const monthlyInterestRate = annualInterestRate / 12 / 100;
  const emi =
    (loanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, tenureInMonths)) /
    (Math.pow(1 + monthlyInterestRate, tenureInMonths) - 1);
  return round(emi);
}

// 2A. Calculate amortization table till loan becomes zero
export function calculateAmortizationTable(
  { badReqExc }: { badReqExc: new (msg: string) => any },
  loanAmount: number,
  initialInterestRate: number,
  initialEMI: number,
  initialTenureInMonths: number,
  interestRateChanges: InterestRateChange[] = [],
  prePayments: PrePayment[] = [],
): AmortizationEntry[] {
  let currentInterestRate = initialInterestRate;
  let remainingBalance = loanAmount;
  let currentEMI = initialEMI;
  const amortizationTable: AmortizationEntry[] = [];
  let month = 1;

  interestRateChanges.sort((a, b) => a.changedAtMonth - b.changedAtMonth);
  prePayments.sort((a, b) => a.month - b.month);

  while (remainingBalance > 0 && month <= MAX_MONTHS) {
    const rateChange = interestRateChanges.find(
      // eslint-disable-next-line no-loop-func
      (change) => change.changedAtMonth === month,
    );
    if (rateChange) {
      currentInterestRate = rateChange.interestRate;
      if (rateChange.adjustEMI) {
        // Recalculate EMI based on the new interest rate and remaining balance
        const remainingMonths = initialTenureInMonths - month + 1;
        currentEMI = calculateEMI(
          remainingBalance,
          currentInterestRate,
          remainingMonths,
        );
      }
      // If adjustEMI is false, we keep the current EMI and the tenure will adjust automatically
    }

    const monthlyInterestRate = currentInterestRate / 12 / 100;
    const interest = round(remainingBalance * monthlyInterestRate);

    if (currentEMI <= interest) {
      throw new badReqExc(
        `EMI is too low to repay the loan at month ${month}. Please increase the EMI amount or adjust the interest rate change.`,
      );
    }

    let principal = round(Math.min(currentEMI - interest, remainingBalance));
    // Check for pre-payment
    // eslint-disable-next-line no-loop-func
    const prePayment = prePayments.find((payment) => payment.month === month);
    if (prePayment) {
      principal += prePayment.amount;
    }

    if (principal > remainingBalance) {
      principal = remainingBalance;
    }
    let actualEMI = round(principal + interest);

    // Adjust the last EMI if it's more than necessary
    if (remainingBalance <= currentEMI) {
      actualEMI = round(remainingBalance + interest);
      principal = round(remainingBalance);
    }

    remainingBalance = round(remainingBalance - principal);

    amortizationTable.push({
      month,
      emi: actualEMI,
      interest,
      principal,
      remainingBalance,
      interestRate: currentInterestRate,
      prePayment: prePayment ? prePayment.amount : 0,
    });

    month++;
  }
  if (month > MAX_MONTHS) {
    throw new badReqExc(
      'Loan repayment exceeds maximum allowed duration (50 years). Please increase the EMI amount or adjust the interest rate changes.',
    );
  }

  return amortizationTable;
}

// 2B. Calculate amortization table till given time
/* function calculateAmortizationTableTillTime(
loanAmount: number,
annualInterestRate: number,
emi: number,
endTime: number,
): AmortizationEntry[] {
const monthlyInterestRate = annualInterestRate / 12 / 100;
let remainingBalance = loanAmount;
const amortizationTable: AmortizationEntry[] = [];
 
for (let month = 1; month <= endTime && remainingBalance > 0; month++) {
    const interest = round(remainingBalance * monthlyInterestRate);
    const principal = round(Math.min(emi - interest, remainingBalance));
    remainingBalance = round(remainingBalance - principal);
 
    amortizationTable.push({
    month,
    emi: round(emi),
    interest,
    principal,
    remainingBalance,
    interestRate: annualInterestRate,
    });
}
 
return amortizationTable;
} */

// 3. Calculate tenure when EMI is given
export function calculateTenure(
  { badReqExc }: { badReqExc: new (msg: string) => any },
  loanAmount: number,
  annualInterestRate: number,
  emi: number,
  interestRateChanges: InterestRateChange[] = [],
  prePayments: PrePayment[] = [],
): number {
  const amortizationTable = calculateAmortizationTable(
    { badReqExc },
    loanAmount,
    annualInterestRate,
    emi,
    MAX_MONTHS,
    interestRateChanges,
    prePayments,
  );
  return amortizationTable.length;
}

// 3A. Calculate amortization table till loan becomes zero (when EMI is given)
// This is the same as calculateAmortizationTable, so we can reuse it

// 3B. Calculate amortization table till given time (when EMI is given)
// This is the same as calculateAmortizationTableTillTime, so we can reuse it

export function calculateTotalInterest(
  amortizationTable: AmortizationEntry[],
): number {
  return round(
    amortizationTable.reduce((total, entry) => total + entry.interest, 0),
  );
}

export function calculateAllLoanDetails(
  { badReqExc }: { badReqExc: new (msg: string) => any },
  loanAmount: number,
  annualInterestRate: number,
  tenureInMonths: number,
  emi: number | null,
  interestRateChanges: InterestRateChange[],
  prePayments: PrePayment[],
): LoanCalculationResult {
  const firstMonthInterest = calculateFirstMonthInterest(
    loanAmount,
    annualInterestRate,
  );
  const calculatedEMI = calculateEMI(
    loanAmount,
    annualInterestRate,
    tenureInMonths,
  );
  const actualInitialEMI = emi || calculatedEMI;
  // let amortizationTable: AmortizationEntry[];
  // let calculatedTenure: number;

  const amortizationTable = calculateAmortizationTable(
    { badReqExc },
    loanAmount,
    annualInterestRate,
    actualInitialEMI,
    tenureInMonths,
    interestRateChanges,
    prePayments,
  );
  const calculatedTenure = amortizationTable.length;

  const totalInterest = calculateTotalInterest(amortizationTable);

  return {
    firstMonthInterest,
    calculatedEMI,
    amortizationTable,
    calculatedTenure,
    totalInterest,
  };
}

// Utility function for currency formatting
const formatCurrency = (amount: number|undefined = 0, style: 'indian' | 'international' = 'indian'): string => {
  if (style === 'indian') {
    return amount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
  } else {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
};
// Utility function to convert number to words (Indian style)
export function numberToWords(num: number): string {
  if (!Number.isFinite(num)) return 'Infinity';
  const scales = ['', 'हज़ार', 'लाख', 'करोड़'];

  if (num === 0) return 'Zero';

  const convertLessThanOneHundred = (n: number): string => {
    // If it's a whole number, remove decimal part
    if (Number.isInteger(n)) {
      return Math.floor(n).toString();
    }

    // Convert to fixed 2 decimal places first
    const fixed = n.toFixed(2);

    // Remove trailing zeros after decimal
    return parseFloat(fixed).toString();
  };

  let words = '';
  let scaleIndex = 0;

  let first = true;
  while (num > 0) {
      let base = 100;
      if (first) {
          first = false;
          base = 1000;
      }
      if (scaleIndex === 3) {
          const re = numberToWords(num) + ' ' + scales[scaleIndex] + ' ' + words;
          return re.trim();
      }
      if (num % base !== 0) {
          words = convertLessThanOneHundred(num % base) + ' ' + scales[scaleIndex] + ' ' + words;
      }
      num = Math.floor(num / base);
      scaleIndex++;
  }

  return words.trim();
};

type GroupByOption = 'none' | 'interestRate' | 'emi' | 'year' | 'financialYear';

const LoanCalculator: React.FC<LoanCalculatorProps> = ({ onLoanDataChange }) => {
  const [loanAmount, setLoanAmount] = useState<number>(38_00_000);
  const [annualInterestRate, setAnnualInterestRate] = useState<number>(8.7);
  const [tenureInMonths, setTenureInMonths] = useState<number>(120);
  const [emi, setEmi] = useState<number>(50_000);
  const [interestRateChanges, setInterestRateChanges] = useState<InterestRateChange[]>([
    { changedAtMonth: 20, interestRate: 9.7, adjustEMI: true, isEnabled: true },
    { changedAtMonth: 40, interestRate: 10.7, adjustEMI: true, isEnabled: true },
  ]);
  const [results, setResults] = useState<LoanCalculationResult>({
    firstMonthInterest: null,
    calculatedEMI: null,
    amortizationTable: null,
    calculatedTenure: null,
    totalInterest: null,
  });
  const [error, setError] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [groupBy, setGroupBy] = useState<GroupByOption>('none');
  const [prePayments, setPrePayments] = useState<PrePayment[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [prePaymentAmount, setPrePaymentAmount] = useState<number>(0);
  const [sidebarWidth, setSidebarWidth] = useState(400);
  const [enablePrepayments, setEnablePrepayments] = useState(false);
  const [enableVariableInterestRate, setEnableVariableInterestRate] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);

  const saveUserData = useCallback(debounce(async () => {
    try {
      const updatedLoan: LoanData = {
        loanAmount,
        annualInterestRate,
        tenureInMonths,
        emi,
        interestRateChanges,
        prePayments,
        enablePrepayments,
        enableVariableInterestRate,
      };
      onLoanDataChange(updatedLoan);
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  }, 800), [loanAmount, annualInterestRate, tenureInMonths, emi, interestRateChanges, prePayments, enablePrepayments, enableVariableInterestRate]);

  useEffect(() => {
    saveUserData();
  }, [loanAmount, annualInterestRate, tenureInMonths, emi, interestRateChanges, prePayments, enablePrepayments, enableVariableInterestRate, saveUserData]);

  const calculateAll = useCallback(async () => {
    try {
      setError('');
      const payload = {
        loanAmount,
        annualInterestRate,
        tenureInMonths,
        emi,
        interestRateChanges: enableVariableInterestRate ? interestRateChanges.filter(change => change.isEnabled) : [],
        prePayments: enablePrepayments ? prePayments : [],
      };
      const response = calculateAllLoanDetails({ badReqExc: Error },
        payload.loanAmount,
        payload.annualInterestRate,
        payload.tenureInMonths,
        payload.emi,
        payload.interestRateChanges,
        payload.prePayments,
      );

      setResults(response);
    } catch (err) {
      if (err instanceof Error && err.message) {
        setError(err.message || 'An error occurred while calculating.');
      } else {
        setError('An unexpected error occurred.');
      }
      setResults({
        firstMonthInterest: null,
        calculatedEMI: null,
        amortizationTable: null,
        calculatedTenure: null,
        totalInterest: null,
      });
    }
  }, [loanAmount, annualInterestRate, tenureInMonths, emi, interestRateChanges, prePayments, enableVariableInterestRate, enablePrepayments]);

  const debouncedCalculateAll = useCallback(
    debounce(calculateAll, 500),
    [calculateAll]
  );

  useEffect(() => {
    debouncedCalculateAll();
    return () => debouncedCalculateAll.cancel();
  }, [debouncedCalculateAll]);

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleEnablePrepayments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnablePrepayments(event.target.checked);
  };

  const handleEnableVariableInterestRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableVariableInterestRate(event.target.checked);
  };
  const addPrePayment = () => {
    if (selectedMonth && prePaymentAmount > 0) {
      setPrePayments([...prePayments, { month: selectedMonth, amount: prePaymentAmount }]);
      setSelectedMonth(null);
      setPrePaymentAmount(0);
    }
  };

  const removePrePayment = (index: number) => {
    setPrePayments(prePayments.filter((_, i) => i !== index));
  };

  const updatePrePayment = (index: number, field: keyof PrePayment, value: number) => {
    const newPrePayments = [...prePayments];
    newPrePayments[index][field] = value;
    setPrePayments(newPrePayments);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const addInterestRateChange = () => {
    setInterestRateChanges([...interestRateChanges, { interestRate: annualInterestRate, changedAtMonth: 1, adjustEMI: true }]);
  };

  const updateInterestRateChange = (index: number, field: keyof InterestRateChange, value: number | boolean) => {
    const newChanges = [...interestRateChanges];
    if (field === 'adjustEMI' || field === 'isEnabled') {
      newChanges[index][field] = value as boolean;
    } else {
      newChanges[index][field] = value as number;
    }
    setInterestRateChanges(newChanges);
  };

  const removeInterestRateChange = (index: number) => {
    setInterestRateChanges(interestRateChanges.filter((_, i) => i !== index));
  };

  const groupAmortizationTable = useMemo(() => {
    if (!results.amortizationTable || groupBy === 'none') {
      return results.amortizationTable;
    }

    const groupedData: AmortizationEntry[] = [];
    let currentGroup: AmortizationEntry | null = null;

    const len = results.amortizationTable.length;
    results.amortizationTable.forEach((entry, index) => {
      const getGroupKey = () => {
        switch (groupBy) {
          case 'interestRate':
            return entry.interestRate;
          case 'emi':
            return entry.emi;
          case 'year':
            return Math.floor((entry.month - 1) / 12);
          case 'financialYear':
            return Math.floor((entry.month + 2) / 12); // Assuming financial year starts in April
          default:
            return null;
        }
      };

      const groupKey = getGroupKey();

      if (!currentGroup || currentGroup.groupKey !== groupKey) {
        if (currentGroup) {
          groupedData.push(currentGroup);
        }
        currentGroup = {
          ...entry,
          groupKey,
          monthStart: entry.month,
          monthEnd: entry.month,
          emiStart: entry.emi,
          emiEnd: entry.emi,
          interestStart: entry.interest,
          interestEnd: entry.interest,
          principalStart: entry.principal,
          principalEnd: entry.principal,
        };
      } else {
        currentGroup.monthEnd = entry.month;
        currentGroup.emi += entry.emi;
        currentGroup.interest += entry.interest;
        currentGroup.principal += entry.principal;
        currentGroup.emiEnd = entry.emi;
        currentGroup.interestEnd = entry.interest;
        currentGroup.principalEnd = entry.principal;
      }

      if (index === len - 1 && currentGroup) {
        groupedData.push(currentGroup);
      }
    });

    return groupedData;
  }, [results.amortizationTable, groupBy]);

  const renderAmortizationTable = (data: AmortizationEntry[]) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell align="right">EMI</TableCell>
            <TableCell align="right">Interest</TableCell>
            <TableCell align="right">Principal</TableCell>
            {enablePrepayments && <TableCell align="right">Pre-Payment</TableCell> }
            <TableCell align="right">Remaining Balance</TableCell>
            <TableCell align="right">Interest Rate (%)</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.monthStart === row.monthEnd ? row.month : `${row.monthStart}-${row.monthEnd}`}
              </TableCell>
              <TableCell align="right">
                {row.emiStart === row.emiEnd
                  ? formatCurrency(row.emi)
                  : `${formatCurrency(row.emiStart)} - ${formatCurrency(row.emiEnd)}`}
              </TableCell>
              <TableCell align="right">
                {row.interestStart === row.interestEnd
                  ? formatCurrency(row.interest)
                  : `${formatCurrency(row.interestStart)} - ${formatCurrency(row.interestEnd)}`}
              </TableCell>
              <TableCell align="right">
                {row.principalStart === row.principalEnd
                  ? formatCurrency(row.principal)
                  : `${formatCurrency(row.principalStart)} - ${formatCurrency(row.principalEnd)}`}
              </TableCell>
              {enablePrepayments && <TableCell align="right">
                {formatCurrency(row.prePayment || 0)}
              </TableCell>}
              <TableCell align="right">{formatCurrency(row.remainingBalance)}</TableCell>
              <TableCell align="right">{row.interestRate.toFixed(2)}%</TableCell>
              <TableCell align="right">
              {enablePrepayments && <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setSelectedMonth(row.month);
                    setPrePaymentAmount(0);
                  }}
                >
                  Add Pre-Payment
                </Button> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderAmortizationGraph = (data: AmortizationEntry[]) => {
    const chartData = {
      labels: data.map(entry => entry.month),
      datasets: [
        {
          label: 'Principal',
          data: data.map(entry => entry.principal),
          backgroundColor: 'rgba(54, 162, 235, 0.8)',
          type: 'bar' as 'bar' | 'line' | undefined,
        },
        {
          label: 'Interest',
          data: data.map(entry => entry.interest),
          backgroundColor: 'rgba(255, 99, 132, 0.8)',
          type: 'bar' as 'bar' | 'line' | undefined,
        },
        {
          label: 'Interest Rate (%)',
          data: data.map(entry => entry.interestRate),
          borderColor: 'rgba(75, 192, 192, 1)',
          type: 'line' as 'bar' | 'line' | undefined,
          yAxisID: 'y1',
          // },
          // {
          //   label: 'Cumulative Interest',
          //   data: data.reduce((acc, entry) => {
          //     const lastValue = acc.length > 0 ? acc[acc.length - 1] : 0;
          //     return [...acc, lastValue + entry.interest];
          //   }, [] as number[]),
          //   borderColor: 'rgba(153, 102, 255, 1)',
          //   type: 'line' as const,
          //   yAxisID: 'y',
        }
      ]
    };

    const options = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const label = context.dataset.label || '';
              const value = context.parsed.y;
              const entry = data[context.dataIndex];
              if (label === 'Principal' || label === 'Interest') {
                return `${label}: ${formatCurrency(value)} (EMI: ${formatCurrency(entry.emi)})`;
              }
              return `${label}: ${value}`;
            },
          },
        },
        title: {
          display: true,
          text: 'EMI Breakdown and Interest Rate Changes'
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: 'Month'
          }
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: 'Amount'
          }
        },
        y1: {
          type: 'linear' as const,
          display: true,
          position: 'right' as const,
          title: {
            display: true,
            text: 'Interest Rate (%)'
          }
        }
      }
    };

    return <Bar data={chartData} options={options} />;
  };

  const AmountDisplay = ({ amount, label }: { amount: number, label: string }) => (
    <Tooltip title={numberToWords(amount)}>
      <Typography>
        {label}: {formatCurrency(amount)}
        <Typography variant="caption" display="block">{numberToWords(amount)}</Typography>
      </Typography>
    </Tooltip>
  );
  const handleResize = (event: React.SyntheticEvent, { size }: { size: { width: number; height: number } }) => {
    setSidebarWidth(size.width);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh', position: 'relative', overflow: 'hidden' }}>
      {/* Resizable Left column - Inputs */}
      <ResizableBox
        width={sidebarWidth}
        height={Infinity}
        minConstraints={[MIN_SIDEBAR_WIDTH, Infinity]}
        maxConstraints={[MAX_SIDEBAR_WIDTH, Infinity]}
        onResize={handleResize}
        resizeHandles={['e']}
        handle={<div className="custom-handle" />}
      >
        <Paper sx={{ height: '100%', padding: '20px', overflowY: 'auto' }}>
        {/* <Typography variant="h4" gutterBottom>
          Loan Calculator
        </Typography> */}
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Loan Amount"
              type="number"
              fullWidth
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number(e.target.value))}
              InputProps={{
                endAdornment: <Typography variant="caption">{numberToWords(loanAmount)}</Typography>
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Annual Interest Rate (%)"
              type="number"
              fullWidth
              value={annualInterestRate}
              onChange={(e) => setAnnualInterestRate(Number(e.target.value))}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Tenure (months)"
              type="number"
              fullWidth
              value={tenureInMonths}
              onChange={(e) => setTenureInMonths(Number(e.target.value))}
            />
          </Grid>
          <Grid item>
            <TextField
              label="EMI"
              type="number"
              fullWidth
              value={emi}
              onChange={(e) => setEmi(Number(e.target.value))}
              InputProps={{
                endAdornment: <Typography variant="caption">{numberToWords(emi)}</Typography>
              }}
            />
          </Grid>
            {enableVariableInterestRate && (
              <Grid item>
                <Typography variant="h6">Interest Rate Changes</Typography>
                {interestRateChanges.map((change, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={1}>
                        <Checkbox
                          checked={change.isEnabled}
                          onChange={(e) => updateInterestRateChange(index, 'isEnabled', e.target.checked)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Interest Rate (%)"
                          type="number"
                          fullWidth
                          value={change.interestRate}
                          onChange={(e) => updateInterestRateChange(index, 'interestRate', Number(e.target.value))}
                          disabled={!change.isEnabled}
                          sx={{ mb: 1 }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Changed At Month"
                          type="number"
                          fullWidth
                          value={change.changedAtMonth}
                          onChange={(e) => updateInterestRateChange(index, 'changedAtMonth', Number(e.target.value))}
                          disabled={!change.isEnabled}
                          sx={{ mb: 1 }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={change.adjustEMI}
                              onChange={(e) => updateInterestRateChange(index, 'adjustEMI', e.target.checked)}
                              disabled={!change.isEnabled}
                            />
                          }
                          label={change.adjustEMI ? "Adjust EMI" : "Adjust Tenure"}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button onClick={() => removeInterestRateChange(index)}>Remove</Button>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button onClick={addInterestRateChange}>Add Interest Rate Change</Button>
              </Grid>
            )}
            {enablePrepayments && (
              <Grid item>
                <Typography variant="h6">Pre-Payments</Typography>
                {prePayments.map((payment, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <TextField
                          label="Month"
                          type="number"
                          fullWidth
                          value={payment.month}
                          onChange={(e) => updatePrePayment(index, 'month', Number(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Amount"
                          type="number"
                          fullWidth
                          value={payment.amount}
                          onChange={(e) => updatePrePayment(index, 'amount', Number(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button onClick={() => removePrePayment(index)}>Remove</Button>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            )}
          <Grid item>
            <Button variant="contained" color="primary" onClick={calculateAll} fullWidth>
              Recalculate All
            </Button>
          </Grid>
        </Grid>
      </Paper>
      </ResizableBox>

      {/* Right column - Results */}
      <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', width: `calc(100% - ${sidebarWidth}px)` }}>
        {/* Upper part - Graph/Table */}
        <Paper sx={{ flexGrow: 1, padding: '20px', overflowY: 'auto' }}>
          {error ? (
            <Alert severity="error">
              <Typography variant="body1">{error}</Typography>
              {error.includes("EMI is too low") && (
                <Typography variant="body2">
                  Suggestion: Try increasing the EMI amount or decreasing the loan amount.
                </Typography>
              )}
              {error.includes("maximum allowed duration") && (
                <Typography variant="body2">
                  Suggestion: Try increasing the EMI amount, decreasing the loan amount, or lowering the interest rate.
                </Typography>
              )}
            </Alert>
          ) : results.amortizationTable && (
            <>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Graph" />
                    <Tab label="Table" />
                  </Tabs>
                </Grid>
                {tabValue === 1 && (
                  <Grid item>
                    <Select
                      value={groupBy}
                      onChange={(e) => setGroupBy(e.target.value as GroupByOption)}
                      displayEmpty
                    >
                      <MenuItem value="none">No Grouping</MenuItem>
                      <MenuItem value="interestRate">Group by Interest Rate</MenuItem>
                      <MenuItem value="emi">Group by EMI</MenuItem>
                      <MenuItem value="year">Group by Year</MenuItem>
                      <MenuItem value="financialYear">Group by Financial Year</MenuItem>
                    </Select>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mt: 2 }}>
                {tabValue === 0 && renderAmortizationGraph(results.amortizationTable)}
                {tabValue === 1 && renderAmortizationTable(groupAmortizationTable || [])}
              </Box>
              {/* Whitespace to prevent content from being hidden behind the lower part */}
              <Box sx={{ height: (LOWER_PART_HEIGHT + 40) + 'px' }} />
            </>
          )}
        </Paper>
      </Box>

      {/* Lower part - Numeric results (Absolute positioned) */}
      <Paper
        sx={{
          position: 'absolute',
          bottom: 0,
          right: '5px',
          width: `calc(90% - ${sidebarWidth}px)`,
          height: LOWER_PART_HEIGHT + 'px',
          padding: '20px',
          overflowY: 'auto',
          zIndex: 1,
          boxShadow: '0px -2px 4px rgba(0,0,0,0.1)' // Optional: adds a shadow to separate it visually
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AmountDisplay amount={results.firstMonthInterest || 0} label="First Month Interest" />
          </Grid>
          <Grid item xs={6}>
            <AmountDisplay amount={results.calculatedEMI || 0} label="Calculated EMI" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Calculated Tenure (months):</Typography>
            <Typography>{results.calculatedTenure !== null ? results.calculatedTenure.toFixed(0) : 'Calculating...'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <AmountDisplay amount={results.totalInterest || 0} label="Total Interest" />
          </Grid>
        </Grid>
      </Paper>

      {selectedMonth && (
        <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', zIndex: 1000 }}>
          <Typography variant="h6">Add Pre-Payment for Month {selectedMonth}</Typography>
          <TextField
            label="Pre-Payment Amount"
            type="number"
            fullWidth
            value={prePaymentAmount}
            onChange={(e) => setPrePaymentAmount(Number(e.target.value))}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={addPrePayment}>
              Add Pre-Payment
            </Button>
            <Button variant="outlined" onClick={() => setSelectedMonth(null)}>
              Cancel
            </Button>
          </Box>
        </Paper>
      )}
      <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}>
        <IconButton onClick={handleSettingsClick}>
          <SettingsIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(settingsAnchorEl)}
        anchorEl={settingsAnchorEl}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem>
            <ListItemText primary="Enable Prepayments" />
            <Checkbox
              edge="end"
              checked={enablePrepayments}
              onChange={handleEnablePrepayments}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Enable Variable Interest Rate" />
            <Checkbox
              edge="end"
              checked={enableVariableInterestRate}
              onChange={handleEnableVariableInterestRate}
            />
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default LoanCalculator;